import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  padding: 6.4rem 4.8rem;
  background-color: rgb(var(--color-primary));
  color: rgb(var(--color-white));

  @media only screen and (max-width: 20em) {
    padding: 4rem 2.4rem;
  }
`;

export const ContactLinksContainer = styled.div`
  margin-top: 6rem;

  @media only screen and (min-width: 48.75em) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const ContactInfo = styled.address`
  font-style: normal;
  font-weight: 300;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  &:last-child {
    margin-bottom: 6rem;
  }

  @media only screen and (min-width: 48.75em) {
    font-weight: 400;
  }
`;

export const ContactInfoHeading = styled.p`
  font-size: 2rem;
  line-height: 2.8rem;
  font-weight: 500;

  @media only screen and (min-width: 48.75em) {
    font-weight: 600;
  }
`;

export const FooterNav = styled.nav`
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  &:last-of-type {
    margin-bottom: 3.6rem;
  }

  @media only screen and (min-width: 48.75em) {
    &:not(:last-child),
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
`;

export const FooterNavHeading = styled.p`
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  & > svg {
    margin-left: 8px;
  }

  @media only screen and (min-width: 48.75em) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
`;

export const FooterNavLinks = styled.ul`
  margin-top: 5px;
  font-size: 1.4rem;
  font-weight: 500;

  @media only screen and (min-width: 48.75em) {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export const FooterNavLink = styled.li`
  text-transform: capitalize;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 48.75em) {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
`;

export const SocialsContainer = styled.div`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.8rem;
  display: flex;
  align-items: center;
  margin-bottom: 3.6rem;

  & > a {
    display: inherit;
  }

  & > *:not(:last-child) {
    margin-right: 2.4rem;
  }
`;

export const CopyrightText = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  padding-top: 3.6rem;
  border-top: 0.5px solid rgb(var(--color-white));

  @media only screen and (min-width: 48.75em) {
    text-align: center;
  }
`;
