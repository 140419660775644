const hireTutor = () => '/hire-tutor';

const services = () => '/services';

const collegeAdmissions = () => '/college-admissions';

const faq = () => '/faq';

const termsAndConditions = () => '/terms-and-conditions';

const privacyPolicy = () => '/privacy-policy';

const becomeATutor = () => '/become-a-tutor';

const blog = () => '/blog';

const bookDrive = () => '/book-drive';

const bookDriveDonations = () => '/book-drive-donations';

const summerProgram = () => '/summer-program';

const usSummerProgram = () => '/us/summer-program';

const routes = {
  hireTutor,
  services,
  collegeAdmissions,
  faq,
  termsAndConditions,
  privacyPolicy,
  becomeATutor,
  blog,
  bookDrive,
  bookDriveDonations,
  summerProgram,
  usSummerProgram,
};

export default routes;
