import { useQuery } from 'react-query';
import * as Sentry from '@sentry/nextjs';

import ReactQueryKeys from '../keys';
import { apiV1 } from '../utils';

import useStore from '../../client-store';

const checkSession = async () => {
  const response = await apiV1.get('/auth/check-session');

  return response.data.data;
};

const useCheckSession = () => {
  const updateUser = useStore((state) => state.updateUser);

  const currentUser = useStore((state) => state.currentUser);

  return useQuery(ReactQueryKeys.CHECK_SESSION, checkSession, {
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    retry: false,
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: !currentUser,
    onSuccess: (user) => {
      if (user) {
        Sentry.setUser({ email: user.primaryEmail, id: user.id });
      }

      updateUser(user);
    },
  });
};

export default useCheckSession;
