import { FC, ReactNode } from 'react';
import Head from 'next/head';

import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';

import useCheckSession from '../../server-store/queries/useCheckSession';

interface IGeneralLayoutProps {
  children: ReactNode;
  removeNav?: boolean;
  pageTitle: string;
}

const GeneralLayout: FC<IGeneralLayoutProps> = ({
  children,
  removeNav,
  pageTitle,
}) => {
  useCheckSession();

  return (
    <>
      <Head>
        <title>{`GATE Academy | ${pageTitle}`}</title>
        <link
          rel="shortcut icon"
          href="/gate-academy.png"
          type="image/x-icon"
        />
      </Head>

      <Header removeNav={removeNav} />

      <main>{children}</main>

      <Footer />
    </>
  );
};

export default GeneralLayout;
