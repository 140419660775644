import { FC, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';

import { Btn, SvgIcon } from '@gate-academy/react';

import {
  SideBarOverlay,
  SideBarWrapper,
  SideBarLinks,
  SideBarLink,
} from './side-bar.styles';

import routes from '../../lib/routes';

interface ISideBarProps {
  closeSideBar: () => void;
  isSideBarOpen: boolean;
}

const SideBar: FC<ISideBarProps> = ({ isSideBarOpen, closeSideBar }) => {
  const router = useRouter();

  const { pathname } = router;

  useEffect(() => {
    router.events.on('routeChangeComplete', closeSideBar);
    router.events.on('routeChangeError', closeSideBar);

    return () => {
      router.events.off('routeChangeComplete', closeSideBar);
      router.events.off('routeChangeError', closeSideBar);
    };
  }, [router, closeSideBar]);

  return (
    <>
      {isSideBarOpen && <SideBarOverlay onClick={() => closeSideBar()} />}

      <AnimatePresence>
        {isSideBarOpen && (
          <SideBarWrapper
            key="sidebar"
            initial={{ opacity: 0, transform: 'translateX(30rem)' }}
            animate={{ opacity: 1, transform: 'translateX(0rem)' }}
            exit={{ opacity: 0, transform: 'translateX(30rem)' }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            <SvgIcon
              iconName="close"
              big
              fillColor="neutral5"
              onClick={() => closeSideBar()}
            />

            <SideBarLinks>
              <SideBarLink isActive={pathname === '/'}>
                <Link href="/">Home</Link>
              </SideBarLink>

              <SideBarLink isActive={pathname.startsWith(routes.hireTutor())}>
                <Link href={routes.hireTutor()}>Hire a tutor</Link>
              </SideBarLink>

              <SideBarLink isActive={false}>
                <Link
                  href="https://legacy.gateacademy.com.ng/become-a-tutor"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Become a tutor
                </Link>
              </SideBarLink>

              <SideBarLink isActive={false}>
                <a href="https://assignmenthelp.gateacademy.com.ng/assignment-order/">
                  Assignment Help
                </a>
              </SideBarLink>

              <SideBarLink isActive={pathname === routes.bookDrive()}>
                <Link href={routes.bookDrive()}>Book Drive</Link>
              </SideBarLink>

              <SideBarLink isActive={pathname === routes.services()}>
                <Link href={routes.services()}>Our services</Link>
              </SideBarLink>

              <SideBarLink isActive={pathname.startsWith(routes.blog())}>
                <Link href={routes.blog()}>Blog</Link>
              </SideBarLink>

              <SideBarLink isActive={false} withButton>
                <Btn
                  as="a"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`}
                  transparentBg
                  block
                >
                  Existing user?
                </Btn>
              </SideBarLink>
            </SideBarLinks>
          </SideBarWrapper>
        )}
      </AnimatePresence>
    </>
  );
};

export default SideBar;
