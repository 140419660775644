import styled from 'styled-components';

interface IHeaderWrapperStyleProps {
  isPageScrolled: boolean;
}
export interface NavItemPropTypes {
  isActive: boolean;
  withButton?: boolean;
}

export const HeaderWrapper = styled.header<IHeaderWrapperStyleProps>`
  background-color: rgb(var(--color-white));
  padding: 3.6rem 4.8rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  transition-property: padding, box-shadow;
  transition-duration: 0.2s;

  & > svg {
    cursor: pointer;
  }

  & img {
    cursor: pointer;
  }

  ${({ isPageScrolled }) =>
    isPageScrolled && 'box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);'}

  @media only screen and (max-width: 20em) {
    padding: 2.4rem;
    padding-bottom: 1.2rem;
  }

  @media only screen and (min-width: 48.75em) {
    padding: 2.4rem 6.4rem;
  }
`;

export const NavList = styled.ul`
  display: flex;
  align-items: center;
`;

export const NavItem = styled.li<NavItemPropTypes>`
  font-size: 1.3rem;
  line-height: 2rem;
  margin-right: 2.4rem;
  text-transform: uppercase;
  white-space: nowrap;

  ${({ isActive }) => isActive && 'color: rgb(var(--color-primary));'}

  ${({ withButton }) =>
    withButton &&
    `
    & > a {
      border-color: rgb(var(--color-primary));
      border-width: 2px;
      padding: 8px 1.2rem;
      font-size: 1.3rem}
    `}
`;
