import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { Btn, SvgIcon, useMediaQuery } from '@gate-academy/react';

import { HeaderWrapper, NavList, NavItem } from './header.styles';

import SideBar from '../side-bar/side-bar.component';

import routes from '../../lib/routes';

import GateLogo from '../../public/gate-academy.png';

interface IHeaderProps {
  removeNav?: boolean;
}

const Header: FC<IHeaderProps> = ({ removeNav }) => {
  //This is needed so as to prevent the use of the 'isGreaterThan750Px' value on the server
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const isGreaterThan1050Px = useMediaQuery('(min-width: 65.625em)');

  const router = useRouter();

  const { pathname } = router;

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  useEffect(() => {
    const handlePageScroll = () => {
      if (window.scrollY > 25) setIsPageScrolled(true);
      else setIsPageScrolled(false);
    };

    document.addEventListener('scroll', handlePageScroll);

    return () => {
      document.removeEventListener('scroll', handlePageScroll);
    };
  }, []);

  return (
    <HeaderWrapper isPageScrolled={isPageScrolled}>
      <Image
        placeholder="blur"
        src={GateLogo}
        alt="GATE Academy"
        width={138}
        height={56}
        quality={100}
        onClick={() => router.push('/')}
      />

      {!removeNav &&
        (isComponentMounted && isGreaterThan1050Px ? (
          <nav>
            <NavList>
              <NavItem isActive={pathname === '/'}>
                <Link href="/">Home</Link>
              </NavItem>

              <NavItem isActive={pathname.startsWith(routes.hireTutor())}>
                <Link href={routes.hireTutor()}>Hire A tutor</Link>
              </NavItem>

              <NavItem isActive={pathname.startsWith(routes.becomeATutor())}>
                <Link href={routes.becomeATutor()}>Become A tutor</Link>
              </NavItem>

              <NavItem isActive={false}>
                <a href="https://assignmenthelp.gateacademy.com.ng/assignment-order/">
                  Assignment Help
                </a>
              </NavItem>

              <NavItem isActive={pathname === routes.bookDrive()}>
                <Link href={routes.bookDrive()}>Book drive</Link>
              </NavItem>

              <NavItem isActive={pathname === routes.services()}>
                <Link href={routes.services()}>Our services</Link>
              </NavItem>

              <NavItem isActive={pathname.startsWith(routes.blog())}>
                <Link href={routes.blog()}>Blog</Link>
              </NavItem>

              <NavItem isActive={false} withButton>
                <Btn
                  as="a"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`${process.env.NEXT_PUBLIC_DASHBOARD_URL}`}
                  transparentBg
                >
                  Existing user?
                </Btn>
              </NavItem>
            </NavList>
          </nav>
        ) : (
          <>
            <SvgIcon
              iconName="menu"
              fillColor="neutral1"
              big
              onClick={() => setIsSideBarOpen(true)}
            />

            <SideBar
              isSideBarOpen={isSideBarOpen}
              closeSideBar={() => setIsSideBarOpen(false)}
            />
          </>
        ))}
    </HeaderWrapper>
  );
};

export default Header;
